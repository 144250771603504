import Logo from '../../images/header/dark_logo.png';

export const theme = {
  background: '#ffffffff',
  nav: {
    logo: Logo
  },
  colors: {
    uiBlack: '#080E14',
    uiBlue: '#5FA5C5',
    uiDarkBlue: '#0F5575',
    uiPurple: '#52368C',
    uiLightBlue: '#6EBDE9',
    academyBlue: '#4EBFEE',
    uiLightYellow: '#F7F7EC',
    uiWhite: '#F5FAFD',
    uiPaleBlue: '#C4DFEC',
    bg: {
      secondary: '#F5FAFD'
    }
  },
  typography: {
    headers: {
      h1: '600 48px Montserrat',
      h1m: '600 36px Montserrat',
      h2: '600 36px Montserrat',
      h2m: '600 28px Montserrat',
      h3: '600 24px Montserrat',
      h4: '600 18px Montserrat'
    },
    body: {
      m: '500 18px Raleway',
      s: '500 16px Raleway',
      xs: '500 14px Raleway'
    },
    quote: {
      m: 'light italic 28px 48px Raleway',
      s: 'light italic 24px 48px Raleway'
    },
    btn: 'semibold 14px 150% Montserrat'
  },
  buttons: {
    fill: {
      bg: '#6ebde9',
      text: 'rgba(255, 255, 255, 90)'
    },
    hollow: {
      color: '#0F5575',
      hover: 'rgba(255, 255, 255, 90)'
    },
    link: {
      color: '#0F5575'
    }
  }
};
